import 'styles/sass/groups.scss';
import { useState } from 'react';
import { GroupScheduleProps } from 'interfaces/schedule';
import { day, hour } from 'constants/schedule';
import { Spin, Form, Image } from 'antd';
import dayjs from 'dayjs';
import { useAppDispatch } from 'hooks/reduxHooks';
import {
  compareNewSchedules,
  compareExistingSchedules,
} from 'hooks/compareOverlappedSchedules';
import { LoadingOutlined } from '@ant-design/icons';
import notificationHook from 'hooks/notificationHook';
import { doEditGroupSchedule } from 'store/slices/groupScheduleSlice';
import { formatScheduleIntervalDate } from 'libs/dateFormaterPetition';
import { GroupScheduleModal } from './GroupScheduleModal';
import { GroupScheduleFeedack } from './GroupScheduleFeedback';
import {
  GroupSchedule,
  ScheduleDay,
  ScheduleComparison,
} from 'types/group-schedule';
import { ApiError } from './../../types';
import statusMessages from 'statusMessages';
import Edit from 'assets/images/icn-edit.svg';

export const GroupsSchedule = ({
  loading,
  groupSchedules,
  userOptions,
  isPublished,
}: GroupScheduleProps) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [scheduleToEdit, setScheduleToEdit] = useState<GroupSchedule>();
  const [conflictingExistingSchedules, setConflictingExistingSchedules] =
    useState<ScheduleDay[]>([]);
  const [conflictingNewSchedules, setConflictingNewSchedules] = useState<
    ScheduleComparison[]
  >([]);

  const handleCloseFeedback = () => setShowFeedbackModal(false);

  const convertScheduleFormat = (schedule: ScheduleDay[]) => {
    return schedule.map((item) => ({
      ...item,
      initialHour: dayjs(item.initialHour, 'HH:mm:ss'),
      endHour: dayjs(item.endHour, 'HH:mm:ss'),
    }));
  };

  const showModal = (schedule: GroupSchedule) => {
    if (!isPublished) {
      const formattedSchedule = convertScheduleFormat(schedule.schedule);
      setScheduleToEdit(schedule);
      form.setFieldValue('teacherId', schedule.teacherId);
      form.setFieldValue('schedule', formattedSchedule);
      setIsModalOpen(true);
    } else {
      notificationHook({
        message: 'No se puede modificar un horario ya publicado',
        type: 'warning',
      });
    }
  };

  const handleCancel = () => setIsModalOpen(false);

  const onEditGroupSchedule = async (values: GroupSchedule) => {
    values.schedule = values.schedule.map((item) => ({
      ...item,
      initialHour: formatScheduleIntervalDate(dayjs(item.initialHour)),
      endHour: formatScheduleIntervalDate(dayjs(item.endHour)),
    }));

    const filteredGroupSchedules = groupSchedules.filter(
      (existingGroup) => existingGroup.subjectId !== scheduleToEdit?.subjectId
    );

    const overlappedExistingSchedules = compareExistingSchedules(
      values,
      filteredGroupSchedules
    );
    const overlappedNewSchedules = compareNewSchedules(values);

    if (
      overlappedExistingSchedules.length > 0 ||
      overlappedNewSchedules.length > 0
    ) {
      setConflictingExistingSchedules(overlappedExistingSchedules);
      setConflictingNewSchedules(overlappedNewSchedules);
      setShowFeedbackModal(true);
      return;
    }

    if (scheduleToEdit) {
      values._id = scheduleToEdit._id;
      values.subjectId = scheduleToEdit.subjectId;
      values.subjectName = scheduleToEdit.subjectName;
      values.group = scheduleToEdit.group;
      values.career = scheduleToEdit.career;
      values.cycle = scheduleToEdit.cycle;
    }
    dispatch(doEditGroupSchedule(values))
      .unwrap()
      .then(() => {
        form.resetFields();
        notificationHook({
          type: 'success',
          message: '¡Se ha editado el horario de grupo con éxito!',
        });
      })
      .catch((error) => {
        const currentStatus = (error as ApiError).statusCode;
        notificationHook({
          message: statusMessages.groupSchedule[currentStatus ?? 500] as string,
          type: 'error',
        });
      })
      .finally(() => {
        setIsModalOpen(false);
      });
  };

  const convertHourToIndex = (time: string) => {
    const [hourStr, minuteStr] = time.split(':');
    const hour = parseInt(hourStr, 10);
    const minutes = parseInt(minuteStr, 10);
    const index = hour - 7;
    const minuteFraction = minutes / 60;
    return index + minuteFraction;
  };

  const Day = () => (
    <thead>
      <tr className="thead-schedule">
        <th>{`\u00A0`}</th>
        {day.map((day) => (
          <th className="th-day" key={day}>
            {day}
          </th>
        ))}
      </tr>
    </thead>
  );

  const Hour = () => (
    <tbody className="tbody-schedule">
      {hour.map((hourLabel, hourIndex) => (
        <tr key={`row-${hourLabel}`}>
          <th className="th-hour">{hourLabel}</th>
          {day.map((dayLabel, dayIndex) => {
            const cellSchedules = groupSchedules.filter((schedule) =>
              schedule.schedule.some(
                (s) =>
                  s.dayOfWeek === dayIndex &&
                  hourIndex <= convertHourToIndex(s.endHour) &&
                  hourIndex + 1 > convertHourToIndex(s.initialHour)
              )
            );
            return (
              <td
                key={`${dayLabel}-${hourLabel}`}
                onClick={() => {
                  if (cellSchedules.length > 0) {
                    showModal(cellSchedules[0]);
                  }
                }}
                className={
                  cellSchedules.length > 0
                    ? cellSchedules.some((s) => !s.teacherName)
                      ? 'subject incomplete'
                      : 'subject complete'
                    : 'transparent'
                }
              >
                {cellSchedules.map((s) => (
                  <div key={s.subjectId}>
                    <Image
                      preview={false}
                      width="10px"
                      src={Edit}
                      rootClassName="subject-icn"
                    />
                    <div>{s.subjectName}</div>
                    <div className="teacher">
                      {s.teacherName || 'SIN DOCENTE'}
                    </div>
                  </div>
                ))}
              </td>
            );
          })}
        </tr>
      ))}
    </tbody>
  );

  return (
    <Spin spinning={loading} tip="Cargando..." indicator={<LoadingOutlined />}>
      <table className="table-schedule" cellSpacing={0} cellPadding={0}>
        <Day />
        <Hour />
      </table>
      <GroupScheduleModal
        isModalOpen={isModalOpen}
        modalTitle={scheduleToEdit?.subjectName}
        scheduleToEdit={scheduleToEdit}
        userOptions={userOptions}
        form={form}
        handleSubmit={onEditGroupSchedule}
        handleCancel={handleCancel}
      />
      <GroupScheduleFeedack
        conflictingExistingSchedules={conflictingExistingSchedules}
        conflictingNewSchedules={conflictingNewSchedules}
        showFeedbackModal={showFeedbackModal}
        handleCloseFeedback={handleCloseFeedback}
      />
    </Spin>
  );
};
